.hero {
    position: relative;
    margin-bottom: 110px;
    z-index: 2;

    @media(min-width: $md-min) {
        margin-bottom: 150px;
    }

    &::after {
        position: absolute;
        content: "";
        left: 0vw;
        bottom: -50px;
        width: 102vw;
        height: 50px;
        background-color: $color--blue;
        border-bottom-left-radius: 100%;
        border-bottom-right-radius: 100%;
    }

    &__title {
        color: #ffffff;
        margin-bottom: 60px;
   

        @media(min-width: $md-min) {
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;
        }

        span:not(:first-of-type) {
            margin-top: 15px;
        }
    }

    &__video {
        position: relative;
        overflow: hidden;
        padding-top: 56.25%;
        margin-bottom: 60px;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
    }

    &__partners {
        margin-bottom: 60px;

        @media(min-width: $md-min) {
            text-align: center;
        }

        @media(max-width: $sm-max) {
            margin-bottom: 30px;
        }

        h4 {
            // font-family: $spacemonoBold;
            font-size: 21px;
            text-align: center;
            color: $color--grey;
        }

        a {
            text-decoration: none;
        }

        img {
            max-width: 300px;

            @media(min-width: $md-min) {
                margin: 0 20px;
            }

            @media(max-width: $sm-max) {
                max-width: 200px;
                margin-bottom: 15px;
            }
        }

        .small-image {
            max-width: 275px;

            @media(max-width: $sm-max) {
                max-width: 185px;
            }
        }
    }

    &__ctas {
        @media(min-width: $md-min) {
            text-align: center;
        }

        @media(max-width: 374px) {
            & a:not(:last-of-type) {
                margin-bottom: 10px;
            }
        }
    }
}