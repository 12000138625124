.winner-row {
    display: flex;
    align-items: stretch;
    border: 1px solid $color--grey;

    @media(max-width: $sm-max) {
        flex-direction: column-reverse;
    }

    &:not(:last-of-type) {
        margin-bottom: 30px;
    }

    &__content,
    &__image {
        padding: 30px;

        @media(max-width: $xs-max) {
            padding: 15px;
        }
    }
    
    &__content {
        @media(min-width: $md-min) {
            width: 80%;
        }

        h3 {
            margin-bottom: 15px;

            // @media(max-width: $xs-max) {
            //     text-align: center;
            // }
        }
        p{
            margin-bottom: 0!important;
        }
    }

    &__image {
        border-bottom: 1px solid $color--grey;

        @media(min-width: $md-min) {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            width: 20%;
            border-left: 1px solid $color--grey;
            border-bottom: none;
        }

        h4 {
            margin-bottom: 15px;
            text-align: center;
        }

        a {
            text-align: center;
            text-decoration: none;
        }

        img {
            max-width: 90%;
            max-height: 55px;
        }
    }
}