.btn {
    display: inline-block;
    appearance: none;
    border-radius: 5px;
    padding: 10px 15px;
    color: #ffffff;
    font-family: $sofia;
    font-weight: 700;
    background-color: $color--brand;
    border: 1px solid $color--brand;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.4s ease;

    // @media(max-width: $xs-max) {
    //    display: block;
    //    text-align: center;
    // }

    &:hover {
        color: $color--brand;
        background-color: transparent;
    }
}