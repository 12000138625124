h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $sofia;
    font-weight: 700;
    font-style: normal;
    margin-top: 0;
    margin-bottom: 25px;
    color: $color--blue;

    @media(max-width: $sm-max) {
        text-align: left!important;
    }
}

h1,
.h1 {
    font-size: 75px;
    line-height: 85px;
    text-align: center;

    @media(max-width: $xs-max) {
        font-size: 36px;
        line-height: 48px;
    }
}

h2,
.h2 {
    font-size: 27px;
    line-height: 35px;
    text-align: center;

    @media(max-width: $xs-max) {
        font-size: 24px;
        line-height: 30px;
    }
}

h3,
.h3 {
    font-size: 21px;
}

h4,
.h4 {
    font-size: 18px;
    line-height: 28px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

p {
    margin-top: 0;
    margin-bottom: 20px;

    + h2,
    + h3 {
        margin-top: 50px;
    }
}

.text-brand {
    color: $color--brand;
}

.underlined-heading {
    margin-left: 10px;
    margin-right: 10px;

    @media(max-width: $xs-max) {
        margin-left: 5px;
        margin-right: 5px;
    }
    
    span {
        display: inline-block;
        box-shadow: 0 10px 0 $color--brand;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: -10px;
        margin-right: -10px;

        @media(max-width: $sm-max) {
            box-shadow: 0 8px 0 $color--brand;
        }

        @media(max-width: $xs-max) {
            box-shadow: 0 5px 0 $color--brand;
            line-height: 40px;
            padding-left: 5px;
            padding-right: 5px;
            margin-left: -5px;
            margin-right: -5px;
        }
    }
}