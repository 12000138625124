/*------------------------------------*\
    VARIABLES
\*------------------------------------*/

// 1.	Typography 
// 2.	Responsive Breakpoints 
// 3.	Colours 
// 4.	Z-Index 

/*------------------------------------*\
   1.	TYPOGRAPHY
\*------------------------------------*/
// $avertaLight: 'averta-light', 'sans-serif';
// $avertaBold: 'averta-bold', 'sans-serif';
$sofia : 'sofia-pro', 'sans-serif';
// $spacemonoRegular: 'spacemono-regular', 'sans-serif';
// $spacemonoBold: 'spacemono-bold', 'sans-serif';

$base-font-size:   16px;
$base-line-height: 1.6; 


/*------------------------------------*\
   2.	RESPONSIVE BREAKPOINTS
\*------------------------------------*/ 
$breakpoint-xs: 480px; 
$breakpoint-sm: 768px;
$breakpoint-md: 960px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1400px;

$nav-breakpoint: $breakpoint-sm;

$xs-min: $breakpoint-xs;
$xs-max: ($breakpoint-sm - 1); //767px

$sm-min: $breakpoint-sm; //768
$sm-max: ($breakpoint-md - 1); //959

$md-min: $breakpoint-md; //960
$md-max: ($breakpoint-lg - 1); //1999

$lg-min: $breakpoint-lg; //1200
$lg-max: ($breakpoint-xl - 1);


/*------------------------------------*\
   3. COLOURS
\*------------------------------------*/
$color--brand:  #061eff;
$color--blue:  #0d0d2d;
$color--grey:    #808080;



/*------------------------------------*\
   4. Z-INDEX
\*------------------------------------*/
$z-100: 100;
$z-200: 200;
$z-300: 300;
$z-400: 400;
$z-500: 500;
$z-600: 600;
$z-700: 700;
$z-800: 800;
$z-900: 900;
$z-1000: 1000;