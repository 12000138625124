// @font-face {
// 	font-family: 'averta-light';
// 	src: url('../../fonts/averta/averta-light.eot?#iefix') format('embedded-opentype'),
//           url('../../fonts/averta/averta-light.woff') format('woff'),
//           url('../../fonts/averta/averta-light.woff2') format('woff2'),
// 					url('../../fonts/averta/averta-light.ttf') format('truetype'),
// 					url('../../fonts/averta/averta-light.svg#averta-light') format('svg');
// 	font-weight: normal;
// 	font-style: normal;
// }

// @font-face {
// 	font-family: 'averta-bold';
// 	src: url('../../fonts/averta/averta-bold.eot?#iefix') format('embedded-opentype'),
//           url('../../fonts/averta/averta-bold.woff') format('woff'),
//           url('../../fonts/averta/averta-bold.woff2') format('woff2'),
// 					url('../../fonts/averta/averta-bold.ttf') format('truetype'),
// 					url('../../fonts/averta/averta-bold.svg#averta-bold') format('svg');
// 	font-weight: normal;
// 	font-style: normal;
// }

@import url("https://use.typekit.net/dha7ijt.css");


@font-face {
	font-family: 'spacemono-regular';
	src: url('../../fonts/space-mono/spacemono-regular.eot?#iefix') format('embedded-opentype'),
          url('../../fonts/space-mono/spacemono-regular.woff') format('woff'),
          url('../../fonts/space-mono/spacemono-regular.woff2') format('woff2'),
					url('../../fonts/space-mono/spacemono-regular.ttf') format('truetype'),
					url('../../fonts/space-mono/spacemono-regular.svg#spacemono-regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'spacemono-bold';
	src: url('../../fonts/space-mono/spacemono-bold.eot?#iefix') format('embedded-opentype'),
          url('../../fonts/space-mono/spacemono-bold.woff') format('woff'),
          url('../../fonts/space-mono/spacemono-bold.woff2') format('woff2'),
					url('../../fonts/space-mono/spacemono-bold.ttf') format('truetype'),
					url('../../fonts/space-mono/spacemono-bold.svg#spacemono-bold') format('svg');
	font-weight: normal;
	font-style: normal;
}
