.partners-row {
    margin-top: 60px;

    @media(min-width: $md-min) {
        display: flex;
        align-items: center;
    }

    &:not(:last-of-type) {
        margin-bottom: 60px;
    }

    &__image {
        @media(min-width: $md-min) {
            width: 25%;
            padding-right: 40px;
        }

        a {
            text-decoration: none;
        }

        img {
            @media(max-width: $sm-max) {
                max-width: 200px;
                display: block;
                margin-bottom: 20px;
            }
        }



        &--small {
            img {
                max-width: 95%;
    
                @media(max-width: $sm-max) {
                    max-width: 185px;
                } 
            }
        }
    }

    &__content {
        @media(min-width: $md-min) {
            width: 75%;
            padding-left: 40px;
            border-left: 1px solid #cccccc;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        p:last-of-type {
            margin-bottom: 0;
        }
    }
}

