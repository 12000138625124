nav {
    position: fixed;
    top: 60px;
    left: calc(100% - 60px);
    transform-origin: top left;
    transform: rotate(90deg);
    width: 750px;
    z-index: $z-300;



    @media(max-width: 1750px) {
        top: 40px;
        left: calc(100% - 30px);
    }

    @media(max-width: $xs-max) {
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        transform: translateX(100%);
        transition: 0.4s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 200px 100px;
        z-index: 200;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        background-color: $color--brand;
        


    }

    .main-menu {
        margin: 0;
        padding: 0;
        display: block;

        

        li {
            display: inline-block;
            margin-right: 10px;
            text-transform: uppercase;
            font-size: 20px;
            color: $color--grey;

            @media(max-width: $xs-max) {
                color: $color--brand;
            }

            @media(max-width: $xs-max) {
                font-size: 18px;
                display: block;
                &:not(:last-of-type){
                    margin-bottom: 15px;
                }

            }
        }
    }
    a {
        color: $color--grey;
        text-decoration: none;
        margin-right: 10px;
        transition: 0.4s ease;
        
        @media(max-width: $xs-max) {
            color: white;
            font-weight: 700;
        }

        &:hover {
            color: $color--grey;
        }
    }
   

}


.menu-trigger{
    position: fixed;
    top: 30px;
    right: 30px;
    display: none;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    z-index: 201;
    width: 30px;
    height: 30px;
    @media(max-width: $xs-max) {
        display: flex;
    }
    
    &__lines{
        display: block;
        position: relative;
        background-color: $color--brand;
        width: 30px;
        height: 2px;
        z-index: 250;

        &:before,
        &:after{
            content: '';
            position: absolute;
            left: 0;
            background-color: $color--brand;
            width: 100%;
            height: 2px;
            transition: 0.4s ease;
        }
        &:before{
            top: -10px;
        }
        &:after{
            bottom: -10px;
        }

    }
}

.nav-open {
    .menu-trigger {
        &__lines {
            background-color: transparent;


            &::before {
                top: 0;
                transform: rotate(45deg);
                background-color: #ffffff;;

            }
            &::after {
                bottom: 0;
                transform: rotate(-45deg);

                background-color: #ffffff;

            }
        }
    }
    nav{
        transform: translateX(0);
    }
}

.nav-ul-hover{
    padding-left: 0;
}