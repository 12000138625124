body {
    font-family: $sofia;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: $color--grey;

    @media(max-width: $xs-max) {
        font-size: 16px;
        line-height: 22px;
    }
}

.container {
    position: relative;
    z-index: 2;
    margin: 0 auto;

    @media(min-width: 1400px) {
        max-width: 1400px
    }

    @media(max-width: 1600px) {
        padding: 0 80px 0 20px;
    }

    @media(max-width: $xs-max) {
        padding: 0 30px 0 30px;
    }

    &--narrow {
        @media(min-width: 1400px) {
            max-width: 1200px
        }
    }
}


.svg-sprite {
    display: none;
}

.text-center {
    text-align: center;
}

img {
    max-width: 100%;
}

.bg-color--blue {
    background-color: $color--blue;
}

.color--pink {
    color: $color--brand;
}

.page-title {
    margin-bottom: 60px;

    @media(min-width: $md-min) {
        margin-bottom: 100px;
    }
}

.intro {
    max-width: 950px;
    margin-bottom: 60px;

    @media(min-width: $md-min) {
        margin-bottom: 80px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
}

.pt {
    padding-top: 50px;
}


//Circles 
html {
    overflow-x: hidden;
}
body {
    overflow-x: hidden;
}

.circles {
    position: relative;
}

.page--judges {
    position: relative;

    &::before {
        position: fixed;
        content: "";
        top: 50%;
        right: -20vw;
        height: 40vw;
        width: 40vw;
        transform: translateY(-50%);
        background-image: url('../../images/opt/branding/Halftone_large.png');
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 100%;

        @media(max-width: $sm-min) {
            right: -40vw;
            height: 80vw;
            width: 80vw;
        }
    }

    &::after {
        position: fixed;
        content: "";
        bottom: -20vw;
        left: -15vw;
        height: 40vw;
        width: 40vw;
        background-image: url('../../images/opt/branding/Halftone_medium.png');
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 100%;
        z-index: 1;

        @media(max-width: $sm-min) {
            bottom: -30vw;
            left: -25vw;
            height: 60vw;
            width: 60vw;
        }
    }
}

.page--home {
    position: relative;
    
    &::before {
        position: fixed;
        content: "";
        top: 0;
        right: -35vw;
        height: 100vh;
        width: 70vw;
        background-image: url('../../images/opt/branding/Halftone_large.png');
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 100%;
        z-index: 1;

        @media(max-width: $sm-min) {
            top: 0;
            right: -25vw;
            height: 60vh;
            width: 50vw;
        }
    }

    &::after {
        position: fixed;
        content: "";
        bottom: -15vw;
        left: -15vw;
        height: 30vw;
        width: 30vw;
        background-image: url('../../images/opt/branding/Halftone_medium.png');
        background-size: contain;
        background-repeat: no-repeat;
        opacity: 0.5;
        border-radius: 100%;
        z-index: 1;

        @media(max-width: $sm-min) {
            bottom: -20vw;
            left: -20vw;
            height: 40vw;
            width: 40vw;
        }
    }
}


.page--awards {
    position: relative;
    
    &::before {
        position: fixed;
        content: "";
        top: -20vw;
        right: -25vw;
        height: 50vw;
        width: 50vw;
        background-image: url('../../images/opt/branding/Halftone_medium.png');
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 100%;
        z-index: 1;

        @media(max-width: $sm-min) {
            top: -30vw;
            right: -40vw;
            height: 70vw;
            width: 70vw;
        }
    }

    &::after {
        position: fixed;
        content: "";
        bottom: -20vw;
        left: -15vw;
        height: 40vw;
        width: 40vw;
        background-image: url('../../images/opt/branding/Halftone_medium.png');
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 100%;
        z-index: 1;

        @media(max-width: $sm-min) {
            bottom: -30vw;
            left: -25vw;
            height: 55vw;
            width: 55vw;
        }
    }
}

.nominations-intro {
    margin-bottom: 40px;
    margin-top: 20px;
    
    @media(min-width: 959px) {
        margin-top: 60px;
        margin-bottom: 60px;
        text-align: center;
    }
}