.judge-row {
    @media(min-width: $sm-min) {
        display: flex;
        align-items: flex-start;
    }

    &:not(:last-of-type) {
        margin-bottom: 60px;
    }

    &__image {
        @media(min-width: $sm-min) {
            width: 20%;
        }

        img {
            border-radius: 100%;
            margin-bottom: 5px;

            @media(max-width: $xs-max) {
                max-width: 200px;
                display: block;
            }

            @media(min-width: $sm-min) {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &__content {
        @media(min-width: $sm-min) {
            width: 80%;
            padding-left: 40px;
        }

        h2 {
            color: $color--brand;
            margin-bottom: 0;
            text-align: left;
        }

        h4 {
            letter-spacing: 3px;
        }

        p:last-of-type {
            margin-bottom: 0;
        }
    }
}

.judge-row__content.text-center {
    text-align: center;
    h2 {
        text-align: center;
        
        @media(max-width: $sm-min) { 
            text-align: left;
        }
    }

    @media(max-width: $sm-min) { 
        text-align: left;
    }
}

.judge-row--full-width .judge-row__content {
    width: 100%;
}