header {
    padding-top: 70px;
    padding-bottom: 60px;
    position: relative;
    z-index: 10;

    @media(min-width: 1200px) {
        padding-bottom: 80px;
    }

    @media(max-width: 1199px) {
        padding-right: 30px;
        padding-left: 30px;
        padding-bottom: 40px;
    }

    &.theme--light {
        background-color: $color--blue;

        p {
            color: #ffffff;
        }
    }

    img {
        @media(max-width: 1750px) {
             left: 30px;
         }

         @media(max-width: 1199px) {
             max-width: 70px;
             display: block;
             margin-bottom: 20px;
         }

         @media(min-width: 1200px) {
            position: absolute;
            top: 20px;
            left: 60px;
         }
    }

    br {
        @media(min-width: 1200px) {
            display: none;
        }
    }

    span {
        @media(max-width: 1199px) {
            display: none;
        }
    }
}

.header__info {
    text-transform: uppercase;

    @media(min-width: 1200px) {
        text-align: center;
        margin: 0 20px;
    }

    @media(max-width: 1199px) {
        font-size: 20px;
    }

    p {
        color: $color--blue;
        margin-bottom: 5px;

        @media(max-width: 1199px) {
            margin-bottom: 20px;
        }
    }

    a {
        font-size: 22px;
        color: $color--brand;
    }
}