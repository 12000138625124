footer {
   padding: 60px;
   padding-bottom: 0;

   @media(max-width: 1750px) {
        padding-right: 30px;
    }

    @media(max-width: 1600px) {
        padding-left: 20px;
    }

    @media(max-width: $xs-max) {
        padding-right: 30px;
        padding-left: 30px;
    }
}

.footer-cta {
    display: flex;
    justify-content: flex-end;

    a {
        position: relative;
        display: inline-block;
        // font-family: $spacemonoBold;
        font-size: 24px;
        text-transform: uppercase;
        text-decoration: none;
        padding: 80px;
        border-radius: 100%;
        color: #ffffff;
        background-color: $color--brand;
        border: 1px solid $color--brand;
        transition: 0.4s ease;
        margin-bottom: 15px;
        z-index: 2;

        @media(max-width: $xs-max) {
            padding: 60px;
            font-size: 20px;
        }

        &:hover {
            color: $color--brand;
            background-color: transparent;
        }

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
}

.footer-legal {
    position: relative;
    z-index: 3;
    font-size: 16px; 

    h2 {
        text-align-last: left;
        margin-bottom: 0;
        color: $color--brand;
    }
    
    // span.copy {
    //     font-size: 25px;
    //     position: relative;
    //     top: 9px;
    // }

    a {
        color: gray;
    }
}